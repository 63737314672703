import React from "react"

import PropTypes from "prop-types"

import { Link } from "gatsby"

import "./newsColumn.scss"
//Images
function truncate(input, length) {
  if (input.length > length) {
    return input.substring(0, length) + "..."
  }
  return input
}
const NewsColumn = props => (
  <div className="column-4 column-mob-12 news-col">
    <article className="news-article">
      <h4 className="news-article__title">{props.title}</h4>
      <p
        className="news-article__excerpt"
        dangerouslySetInnerHTML={{
          __html: truncate(props.excerpt.replace(/(<([^>]+)>)/gi, ""), 160),
        }}
      ></p>
      <Link to={props.slug} className="news-article__btn">
        Les mer
      </Link>
    </article>
  </div>
)

NewsColumn.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  slug: PropTypes.string,
}

export default NewsColumn
