import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import "./newsCategories.scss"
//Images

const NewsCategories = props => {
  const query = useStaticQuery(graphql`
    {
      allWpCategory(sort: { fields: [databaseId], order: ASC }) {
        nodes {
          databaseId
          name
          slug
          uri
        }
      }
    }
  `)
  return (
    <div className="cat-wrapper">
      {query.allWpCategory.nodes.map(item => {
        return (
          <Link
            to={item.uri}
            className="cat-pill"
            key={item.slug}
            data-id={item.slug}
          >
            {item.name}
          </Link>
        )
      })}
    </div>
  )
}

NewsCategories.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
}

export default NewsCategories
