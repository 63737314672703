import PropTypes from "prop-types"
import React from "react"
import "./newsSectionHeader.scss"
//Images

const NewsSectionHeader = props => (
  <div className="news-section__header">
    <h2 className="news-section__title">{props.title}</h2>
    <div
      className="news-section__content"
      dangerouslySetInnerHTML={{ __html: props.text }}
    ></div>
  </div>
)

NewsSectionHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default NewsSectionHeader
